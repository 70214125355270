import React, { useState } from 'react';
import './PaymentPage.css';

const PaymentPage = () => {
  const [paymentOption, setPaymentOption] = useState('oneTime');

  const handlePaymentOptionChange = (event) => {
    setPaymentOption(event.target.value);
  };

  return (
    <div className="payment-page">
      <h1>Our Service Payment Options</h1>
      <div className="payment-card">
        <div className="card-header">
          <h2>Choose Your Payment Option</h2>
        </div>
        <div className="card-content">
          <div className="radio-group">
            <div className="radio-item">
              <input
                type="radio"
                id="oneTime"
                name="paymentOption"
                value="oneTime"
                checked={paymentOption === 'oneTime'}
                onChange={handlePaymentOptionChange}
              />
              <label htmlFor="oneTime">One-Time Payment (No API Key)</label>
            </div>
            <div className="radio-item">
              <input
                type="radio"
                id="oneTimeApi"
                name="paymentOption"
                value="oneTimeApi"
                checked={paymentOption === 'oneTimeApi'}
                onChange={handlePaymentOptionChange}
              />
              <label htmlFor="oneTimeApi">One-Time Payment (With API Key)</label>
            </div>
            <div className="radio-item">
              <input
                type="radio"
                id="subscription"
                name="paymentOption"
                value="subscription"
                checked={paymentOption === 'subscription'}
                onChange={handlePaymentOptionChange}
              />
              <label htmlFor="subscription">Subscription (Plus Plan)</label>
            </div>
          </div>

          <div className="payment-details">
            {paymentOption === 'oneTime' && (
              <div>
                <h3>One-Time Payment (No API Key)</h3>
                <p>Get instant access to our service without an API key. Perfect for single-use or testing.</p>
                <p>Price: $49.99</p>
                <button className="purchase-button">Purchase Now</button>
              </div>
            )}
            {paymentOption === 'oneTimeApi' && (
              <div>
                <h3>One-Time Payment (With API Key)</h3>
                <p>Get full access to our service with an API key. Ideal for developers and integrations.</p>
                <p>Price: $99.99</p>
                <button className="purchase-button">Purchase Now</button>
              </div>
            )}
            {paymentOption === 'subscription' && (
              <div>
                <h3>Subscription (Plus Plan)</h3>
                <p>Get ongoing access to our service with regular updates and premium support.</p>
                <p>Price: $19.99/month</p>
                <button className="purchase-button">Subscribe Now</button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;