import axios from 'axios';

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

if (!OPENAI_API_KEY) {
  throw new Error('Missing required OpenAI API key. Please check your .env file.');
}

if (!GOOGLE_API_KEY) {
  throw new Error('Missing required Google API key. Please check your .env file.');
}

const openaiClient = axios.create({
  baseURL: 'https://api.openai.com/v1/chat/completions',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${OPENAI_API_KEY}`
  }
});

const googleClient = axios.create({
  baseURL: 'https://google.serper.dev/search',
  headers: {
    'Content-Type': 'application/json',
    'X-API-KEY': GOOGLE_API_KEY
  }
});

export const generateAIResponse = async (query) => {
  try {
    const response = await openaiClient.post('', {
      model: "gpt-3.5-turbo",
      messages: [
        { role: 'system', content: 'You are a search engine. Provide a precise and concise answer based on the following query.' },
        { role: 'user', content: query }
      ],
      temperature: 0.7,
      max_tokens: 300
    });
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error generating AI response:', error);
    return 'Error generating AI response. Please try again later.';
  }
};

export const generateLinks = async (query) => {
  try {
    const response = await googleClient.post('', { q: query });
    if (response.data && Array.isArray(response.data.organic)) {
      const links = response.data.organic.map((result, index) => ({
        id: index,
        title: result.title,
        link: result.link
      }));
      return links;
    } else {
      console.error('Unexpected response structure:', response.data);
      return [];
    }
  } catch (error) {
    console.error('Error generating links:', error);
    return [];
  }
};
