import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { store } from './redux/store';
import { AIResponseProvider } from './contexts/AIResponseContext'; // Import the context provider
import SavioChat from './components/SavioChat';
import SearchComponent from './components/SearchComponent';
import CreateSavioz from './components/CreateSavioz';
import ResponseSection from './components/ResponseSection';
import WaitingListPage from './components/WaitingList';
import PaymentPage from './components/PaymentPage';

const App = () => {
  return (
    <Provider store={store}>
      <AIResponseProvider>
        <Router>
         
            <Routes>
              <Route path="/" element={<SearchComponent/>} />
              <Route path="/search" element={<SearchComponent />} />
              <Route path="/chat" element={<SavioChat />} />
              <Route path="/CreateSavioz" element={<PaymentPage />} />
              <Route path="/h" element={<SearchComponent/>} />
              <Route path="/response" element={<ResponseSection isLoading={false} aiResponse="Sample response" />} />
            </Routes>
          
        </Router>
      </AIResponseProvider>
    </Provider>
  );
};

const Home = () => <h1>Welcome to the Home Page</h1>;

export default App;

/* <div className="App">
            <nav>
              <ul>
                <li>
                  <Link to="/search">Home</Link>
                </li>
                <li>
                  <Link to="/search">Search</Link>
                </li>
                <li>
                  <Link to="/chat">Chat</Link>
                </li>
              </ul>
            </nav>
 */