import React, { useState, useEffect, useRef, useContext } from 'react';
import { MessageSquare, Send, Settings, User, Zap, ChevronDown, X, Plus, Globe, Link } from 'lucide-react';
import './Saviochat.css';
import { AIResponseContext } from '../contexts/AIResponseContext'; // Import the context

const AI_MODELS = [
  { id: 'gpt-3.5', name: 'GPT-3.5', color: '#10B981' },
  { id: 'gpt-4', name: 'GPT-4', color: '#3B82F6' },
  { id: 'claude', name: 'Claude', color: '#8B5CF6' },
  { id: 'dall-e', name: 'DALL-E', color: '#EF4444' },
];

const SavioChat = () => {
  const [activeView, setActiveView] = useState('singleChat');
  const [conversations, setConversations] = useState([
    { id: 1, title: 'Conversation 1', messages: [] },
    { id: 2, title: 'Conversation 2', messages: [] },
  ]);
  const [activeConversation, setActiveConversation] = useState(1);
  const [inputValue, setInputValue] = useState('');
  const [selectedModel, setSelectedModel] = useState(AI_MODELS[0]);
  const [isModelMenuOpen, setIsModelMenuOpen] = useState(false);
  const endOfMessagesRef = useRef(null);
  const { aiResponse } = useContext(AIResponseContext); // Use the context

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [conversations]);

  useEffect(() => {
    if (aiResponse) {
      const aiMessage = { id: Date.now(), text: aiResponse, sender: 'ai' };
      setConversations(prevConversations => 
        prevConversations.map(conv => 
          conv.id === activeConversation 
            ? { ...conv, messages: [...conv.messages, aiMessage] }
            : conv
        )
      );
    }
  }, [aiResponse, activeConversation]);

  const handleSend = async () => {
    if (inputValue.trim()) {
      const newMessage = { id: Date.now(), text: inputValue, sender: 'user' };
      setConversations(prevConversations => 
        prevConversations.map(conv => 
          conv.id === activeConversation 
            ? { ...conv, messages: [...conv.messages, newMessage] }
            : conv
        )
      );
      setInputValue('');
      
      // Simulate AI response
      setTimeout(() => {
        const aiResponse = { id: Date.now(), text: `Réponse de ${selectedModel.name} à "${inputValue}"`, sender: 'ai' };
        setConversations(prevConversations => 
          prevConversations.map(conv => 
            conv.id === activeConversation 
              ? { ...conv, messages: [...conv.messages, aiResponse] }
              : conv
          )
        );
      }, 1000);
    }
  };

  const addNewConversation = () => {
    const newId = Math.max(...conversations.map(c => c.id)) + 1;
    setConversations([...conversations, { id: newId, title: `Conversation ${newId}`, messages: [] }]);
    setActiveConversation(newId);
  };

  const addNewBot = () => {
    // Logic to add a new bot to the conversation
    console.log("Adding new bot to conversation");
  };

  return (
    <div className="saviochat-container">
      {/* Sidebar */}
      <div className="sidebar">
        <div className="sidebar-header">
          <h1>Saviochat</h1>
          <p>Assistant IA professionnel</p>
        </div>
        <nav className="sidebar-nav">
          <a href="#" className={`nav-item ${activeView === 'singleChat' ? 'active' : ''}`} onClick={() => setActiveView('singleChat')}>
            <MessageSquare />
            Conversation simple
          </a>
          <a href="#" className={`nav-item ${activeView === 'doubleChat' ? 'active' : ''}`} onClick={() => setActiveView('doubleChat')}>
            <MessageSquare />
            Conversation double
          </a>
          <a href="#" className={`nav-item ${activeView === 'interconnect' ? 'active' : ''}`} onClick={() => setActiveView('interconnect')}>
            <Link />
            Interconnexion
          </a>
          <a href="#" className="nav-item">
            <Globe />
            Connexion Internet
          </a>
          <a href="#" className="nav-item">
            <Settings />
            Paramètres
          </a>
          <a href="#" className="nav-item">
            <User />
            Profil
          </a>
        </nav>
        <button className="add-conversation-button" onClick={addNewConversation}>
          <Plus /> Nouvelle conversation
        </button>
      </div>

      {/* Main chat area */}
      <div className="chat-area">
        {/* Chat header */}
        <header className="chat-header">
          <div className="model-selector">
            <button
              onClick={() => setIsModelMenuOpen(!isModelMenuOpen)}
              className="model-button"
            >
              <Zap />
              <span>{selectedModel.name}</span>
              <ChevronDown />
            </button>
            {isModelMenuOpen && (
              <div className="model-menu">
                {AI_MODELS.map((model) => (
                  <button
                    key={model.id}
                    onClick={() => {
                      setSelectedModel(model);
                      setIsModelMenuOpen(false);
                    }}
                    className="model-option"
                  >
                    {model.name}
                  </button>
                ))}
              </div>
            )}
          </div>
          <button className="add-bot-button" onClick={addNewBot}>
            <Plus /> Ajouter un bot
          </button>
          <button className="close-button">
            <X />
          </button>
        </header>

        {/* Chat content */}
        {activeView === 'singleChat' && (
          <div className="single-chat-view">
            <div className="messages-container">
              {conversations.find(c => c.id === activeConversation)?.messages.map((message) => (
                <div
                  key={message.id}
                  className={`message ${message.sender}`}
                >
                  <div className="message-content">
                    {message.text}
                  </div>
                </div>
              ))}
              <div ref={endOfMessagesRef} />
            </div>
          </div>
        )}

        {activeView === 'doubleChat' && (
          <div className="double-chat-view">
            {/* Implement double chat view here */}
            <p>Double chat view (to be implemented)</p>
          </div>
        )}

        {activeView === 'interconnect' && (
          <div className="interconnect-view">
            {/* Implement interconnect view here */}
            <p>Interconnect view (to be implemented)</p>
          </div>
        )}

        {/* Input area */}
        <div className="input-area">
          <input
            type="text"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            placeholder="Tapez votre message..."
            onKeyPress={(e) => e.key === 'Enter' && handleSend()}
          />
          <button onClick={handleSend} className="send-button">
            <Send />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SavioChat;

