import React from 'react';
import './LinksSection.css';

const LinksSection = ({ results = [], handleSearch }) => {
  return (
    <div className="links-section">
      <div className="header">
        <h2>Search Results</h2>
        <button onClick={handleSearch} className="refresh-button" aria-label="Refresh search">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor">
            <path d="M17.65 6.35A7.958 7.958 0 0012 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0112 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/>
          </svg>
        </button>
      </div>
      <p className="info">About {results.length} results (0.{Math.floor(Math.random() * 90 + 10)} seconds)</p>
      {results.map((result, index) => (
        <div key={index} className="result-item">
          <a href={result.link} target="_blank" rel="noopener noreferrer" className="result-title">
            {result.title}
          </a>
          <p className="result-url">{result.link}</p>
          <p className="result-snippet">{result.snippet}</p>
        </div>
      ))}
    </div>
  );
};

export default LinksSection;
