import React, { useState, useEffect, useContext } from 'react';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import './ResponseSection.css';
import { AIResponseContext } from '../contexts/AIResponseContext'; // Import the context

const ResponseSection = ({ isLoading, aiResponse }) => {
  const [displayedResponse, setDisplayedResponse] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();
  const { setAiResponse } = useContext(AIResponseContext); // Use the context

  useEffect(() => {
    if (!isLoading && aiResponse) {
      const timer = setTimeout(() => {
        if (currentIndex < aiResponse.length) {
          setDisplayedResponse(aiResponse.slice(0, currentIndex + 1));
          setCurrentIndex(currentIndex + 1);
        }
      }, 30);

      return () => clearTimeout(timer);
    }
  }, [isLoading, aiResponse, currentIndex]);

  const handleContinueWithSavioGPT = () => {
    setAiResponse(aiResponse); // Set the AI response in the context
    navigate('/chat');
  };

  return (
    <div className="response-section">
      <h2>AI Response</h2>
      {isLoading ? (
        <div className="loading-spinner">
          <div className="spinner"></div>
          <p>Analyzing your query and generating a response...</p>
        </div>
      ) : (
        <>
          <p className="ai-response">{displayedResponse}</p>
          <button onClick={handleContinueWithSavioGPT} className="continue-button">
            Continuer
            <ArrowRight size={16} className="continue-button-icon" />
          </button>
          <p className="disclaimer">*Attention: Vérifiez toujours la véracité des informations générées par l'IA*</p>
        </>
      )}
    </div>
  );
};

export default ResponseSection;



//Bearer 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 Attention: Vérifiez toujours la véracité des informations générées par l'IA