import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { generateAIResponse, generateLinks } from '../api'; // Adjust the import path as necessary

export const fetchGeneratedLinks = createAsyncThunk(
  'search/fetchGeneratedLinks',
  async (query, { rejectWithValue }) => {
    try {
      const links = await generateLinks(query);
      console.log('Fetched links:', links); // Debugging log
      return links;
    } catch (error) {
      console.error('Error in fetchGeneratedLinks:', error);
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAIResponse = createAsyncThunk(
  'search/fetchAIResponse',
  async (query, { rejectWithValue }) => {
    try {
      const response = await generateAIResponse(query);
      console.log('Fetched AI response:', response); // Debugging log
      return response;
    } catch (error) {
      console.error('Error in fetchAIResponse:', error);
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  query: '',
  isLoading: false,
  error: null,
  aiResponse: null,
  generatedLinks: [],
  saviozList: [
    { _id: "savioz1", name: "John Savioz", aiModel: "gpt3", language: "en", pricingPlan: "basic", role: "Developer", services: ["Code Review", "Bug Fixing", "Development Consultation"], createdAt: "2024-08-01T10:00:00.000Z" },
    { _id: "savioz2", name: "Emma Savioz", aiModel: "gpt3.5", language: "fr", pricingPlan: "pro", role: "Marketing Specialist", services: ["Market Analysis", "SEO Optimization", "Content Strategy"], createdAt: "2024-08-02T10:00:00.000Z" },
    { _id: "savioz3", name: "Alex Savioz", aiModel: "gpt4", language: "es", pricingPlan: "enterprise", role: "Project Manager", services: ["Project Planning", "Resource Allocation", "Risk Management"], createdAt: "2024-08-03T10:00:00.000Z" },
    { _id: "savioz4", name: "Liu Savioz", aiModel: "gpt4", language: "zh", pricingPlan: "standard", role: "Customer Support", services: ["Customer Onboarding", "Support Ticket Handling", "Feedback Collection"], createdAt: "2024-08-04T10:00:00.000Z" },
    { _id: "savioz5", name: "Sara Savioz", aiModel: "gpt3", language: "de", pricingPlan: "premium", role: "Sales Manager", services: ["Lead Generation", "Sales Closing", "Client Relations"], createdAt: "2024-08-05T10:00:00.000Z" }
  ]
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setQuery: (state, action) => {
      state.query = action.payload;
    },
    clearResults: (state) => {
      state.aiResponse = null;
      state.generatedLinks = [];
      state.error = null;
    },
    updateSaviozList: (state, action) => {
      const { _id, updates } = action.payload;
      const index = state.saviozList.findIndex(s => s._id === _id);
      if (index !== -1) {
        state.saviozList[index] = {...state.saviozList[index], ...updates};
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGeneratedLinks.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchGeneratedLinks.fulfilled, (state, action) => {
        console.log('Payload on fulfilled:', action.payload); // Debugging log
        state.isLoading = false;
        state.generatedLinks = action.payload;
      })
      .addCase(fetchGeneratedLinks.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAIResponse.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchAIResponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.aiResponse = action.payload;
      })
      .addCase(fetchAIResponse.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  }
});

export const { setQuery, clearResults, updateSaviozList } = searchSlice.actions;

export default searchSlice.reducer;
