// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import searchReducer from './searchSlice';

export const store = configureStore({
  reducer: {
    search: searchReducer,
  },
});

// For debugging
store.subscribe(() => console.log('Store state:', store.getState()));



//Bearer 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