import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setQuery, clearResults, fetchGeneratedLinks, fetchAIResponse } from '../redux/searchSlice';
import './SearchComponent.css';
import image1 from '../assets/search3.png';
import ResponseSection from './ResponseSection';
import LinksSection from './LinksSection';
import SaviozSection from './SaviozSection';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash'; // Make sure to install lodash if not already included

const BEST_SAVIOZ_BOTS = [
  { id: 1, name: 'Savioz Bot 1', description: 'This is Savioz Bot 1 description.' },
  { id: 2, name: 'Savioz Bot 2', description: 'This is Savioz Bot 2 description.' },
  { id: 3, name: 'Savioz Bot 3', description: 'This is Savioz Bot 3 description.' }
];

const SearchComponent = () => {
  const dispatch = useDispatch();
  const { query, isLoading, error, aiResponse, generatedLinks = [] } = useSelector((state) => state.search); // Default to an empty array
  let navigate = useNavigate();

  const debouncedSearch = debounce((query) => {
    dispatch(fetchAIResponse(query));
    dispatch(fetchGeneratedLinks(query));
  }, 300);

  const handleSearch = () => {
    debouncedSearch(query);
  };

  const handleClearResults = () => {
    dispatch(clearResults());
  };

  const handleClick = () => {
    navigate('/createsavioz');
  };

  return (
    <div className="search-container">
      <header>
        <nav>
          <button>Contact</button>
          <button onClick={handleClick}>Want your own search engine?</button>
          <select aria-label="Select language">
            <option value="fr">Langue: FR</option>
            <option value="en">Language: EN</option>
          </select>
        </nav>
      </header>

      <main>
        <h1 className="main-title">⚡</h1>
        <img src={image1} alt="Search illustration" className="myImage" />
        <p className="veracite-message">Message: attention à la véracité</p>
        <div className="search-box">
          <input
            type="text"
            value={query}
            onChange={(e) => dispatch(setQuery(e.target.value))}
            placeholder="Search securely..."
            className="search-input"
            aria-label="Search input"
          />



          
          
          <button onClick={handleSearch} className="search-button" disabled={isLoading}>
            {isLoading ? 'Searching...' : 'Search'}
          </button>
        </div>

        {error && <div className="error-message">{error}</div>}

        <div className="results-area">
          {aiResponse && (
            <ResponseSection
              isLoading={isLoading}
              aiResponse={aiResponse}
            />
          )}
          {generatedLinks.length > 0 && (
            <LinksSection
              isLoading={isLoading}
              results={generatedLinks}
              handleSearch={handleSearch}
            />
          )}
           
        </div>

      
      </main>

      <footer>
        <nav>
          <button>Contact</button>
          <button onClick={handleClick}>Want your own search engine?</button>
          <select aria-label="Select language footer">
            <option value="fr">Langue: FR</option>
            <option value="en">Language: EN</option>
          </select>
        </nav>
      </footer>
    </div>
  );
};

export default SearchComponent;




//<SaviozSection bestSavioz={BEST_SAVIOZ_BOTS} />



//<button onClick={() => console.log("Show suggestions")} className="suggestions-button">
//Suggestions
//</button>
