import React, { createContext, useState } from 'react';

export const AIResponseContext = createContext();

export const AIResponseProvider = ({ children }) => {
  const [aiResponse, setAiResponse] = useState('');

  return (
    <AIResponseContext.Provider value={{ aiResponse, setAiResponse }}>
      {children}
    </AIResponseContext.Provider>
  );
};
